let menuData = [
    {
        "title": "用户管理",
        "key":"users",
        "children": [
            {
                "title": "管理员",
                "url": "/a/admins",
            },{
                "title": "教师",
                "url": "/a/teachers",
            },{
                "title": "学生",
                "url": "/a/students",
            },]
    },
    {
        "title": "教学管理",
        "key":"teach_manage",
        "children": [
            {
                "title": "作业箱管理",
                "url": "/a/report/boxes"
            },
            {
                "title": "课程",
                "url": "/a/courses",
            },
            {
                "title": "在线实验",
                "url": "/a/online_courses",
            },
            {
                "title": "名单管理",
                "url": "/a/name_list"
            },
            {
                "title": "作业箱统计",
                "url": "/a/report/statistics"
            },
            {
                "title": "插件管理",
                "url": "/a/word_addin",
            },
            {
                "title": "软件下载",
                "url": "/s/support_download"
            },
            {
                "title": "文件上传",
                "url": "/a/software"
            },
        ]
    },
    {
        "title": "排课管理",
        "key":"paike",
        "children": [
            {
                "title": "排课计划",
                "url": "/t/course_schedules"
            },
            {
                "title": "学期",
                "url": "/a/semesters"
            },
            {
                "title": "排课记录",
                "url": "/t/schedules_record"
            },
            {
                "title": "课表",
                "url": "/a/schedule_view"
            },
            {
                "title": "课时设置",
                "url": "/a/classtime_setting",
            },
        ]
    },

    {
        "title": "实验室管理",
        "key":"room",
        "children": [
            {
                "title": "实验室",
                "url": "/a/rooms",
            },
            {
                "title": "实验室签到记录",
                "url": "/a/room_checkin_records",
            },
        ]
    },
    {
        "key": "auto_borrow",
        "title": "物料",
        "children": [
            {
                "title": "类目管理",
                "url": "/auto_borrow/categorys"
            },
            {
                "title": "物品与库存",
                "url": "/auto_borrow/kinds"
            },
            {
                "title": "借用",
                "url": "/auto_borrow/borrow_goods"
            },
            {
                "title": "批量领取",
                "url": "/auto_borrow/batch_borrow"
            },
            {
                "title": "白名单",
                "url": "/white_list"
            },
            {
                "title": "领取",
                "url": "/auto_borrow/get_goods"
            },
            {
                "title": "归还",
                "url": "/auto_borrow/returnToWarehouse"
            },
            {
                "title": "值班时间",
                "url": "/auto_borrow/borrow_time"
            },
            {
                "title": "维护",
                "url": "/auto_borrow/fault_records"
            },
          
            {
                "title": "物料统计",
                "url": "/auto_borrow/query_records"
            },
            {
                "title": "物料柜",
                'children': [
                    {
                        "title": "物料柜管理",
                        "url": "/auto_borrow/cabinet_manage"
                    },
                    // {
                    //     "title": "物料上架",
                    //     "url": "/auto_borrow/cabinet_shelves"
                    // },
                    {
                        "title": "物料柜预览",
                        "url": "/auto_borrow/cabinet_preview"  
                    },
                ]
            }
        ]
    },
    {
        "title": "系统对接",
        "key":"duijie",
        "children": [
            {
                "title": "口袋实验箱绑定管理",
                "url": "/a/bind_list"
            },
            {
                "title": "实验箱使用数据",
                "url": "/a/emooc_records"
            },
            // {
            //     "title": "实验箱过程数据",
            //     "url": "/a/emooc_device_records"
            // },
        ]
    },
    {
        "title": "系统操作日志",
        "key":"logs",
        "url": "/a/logs"
    },

    {
        "title": "意见建议",
        "key":"support",
        "url": "https://support.qq.com/product/378305"
    },
    // {
    //     "title": "可视化",
    //     "key":"keshihua",
    //     "children": [
    //         {
    //             "title": "配置",
    //             "url": "/a/setting"
    //         },
    //         {
    //             "title": "大屏可视化",
    //             "url": "/visualization"
    //         }
    //     ]
    // }
];
if(!window.config.is_open_paike){
    menuData=menuData.filter(menu=>menu.key!=='paike'&&menu.key!=='room')
}
if(!window.config.is_open_wuliao){
    menuData=menuData.filter(menu=>menu.key!=='records'&&menu.key!=='auto_borrow')
}


module.exports = menuData;
